// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'


.lv-header
  &__banner-advantage a
    text-decoration: underline

  &__container
    padding: 0 0 16px
    @media (min-width: $tablet)
      padding: 0 0 24px
    @media (min-width: $screen-md)
      padding-top: 8px

  &__headline
    @include fontSLH(24px, 32px)
    margin-bottom: 16px
    @media (min-width: $tablet)
      margin-bottom: 8px
    @media (min-width: $desktop-md)
      @include fontSLH(30px, 44px)

  &__capitalize-title
    text-transform: capitalize

  &__empty-search
    margin: 0

  &__read-more
    color: $green
    margin-left: 4px
    display: inline-block
    text-decoration: underline
    &:hover
      cursor: pointer
      color: $green-hover

  &__banner
    display: none
    @media (min-width: $tablet)
      display: block
    @media (min-width: $desktop-md)
      display: flex
      justify-content: flex-end
    @media (min-width: $desktop)
      display: block

  &__banner-wrapper
    @media (min-width: $desktop-md)
      max-width: 276px
    @media (min-width: $desktop)
      max-width: 100%
      margin-top: 6px

  &__banner-item
    display: flex
    margin-bottom: 2px
    align-items: flex-start
    svg
      min-width: 16px
      margin-right: 8px
      margin-top: 4px
      position: relative
      fill: $green
    &:last-child
      margin-bottom: 0
    &--phone span
      display: flex
      flex-wrap: wrap
    span
      @include fontSLH(16px, 24px)
      div > a:hover
        color: $green-hover

  &__description
    @include fontSLH(16px, 24px)
    @media (min-width: $tablet)
      max-width: 413px
    @media (min-width: $desktop-md)
      max-width: 100%
