// MOBILE-FIRST
@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'


.bottom-seo
  margin-top: 40px
  margin-bottom: 34px
  @media (min-width: $tablet)
    margin-top: 0
    margin-bottom: 64px

  p
    @include fontSLH(16px, 24px)
    margin-bottom: 0
    margin-top: 0

  h2
    @include fontSLH(21px, 28px)
    margin-bottom: 24px
    @media (min-width: $desktop-md)
      @include fontSLH(24px, 32px)

  .description-padding p
    margin-bottom: 40px


  &__section
    margin-bottom: 40px

  &__img
    width: 100%
    height: auto
    margin-bottom: 16px
    border-radius: 4px

  &__text-content
    font-size: 16px
    line-height: 24px
    h3
      @include fontSLH(18px, 26px)
      margin-top: 24px
      margin-bottom: 16px
      @media (min-width: $desktop-md)
        margin-top: 32px
    h4
      @include fontSLH(16px, 24px)
      margin: 10px 0
    &--video
      @media (min-width: $tablet)
        column-count: 2
        column-gap: 16px

  &__section-content
    @media (min-width: $tablet)
      column-count: 2
      column-gap: 16px
    @media (min-width: $desktop-md)
      column-count: 3

  iframe
    width: 100%
    height: 100%
    position: relative
    top: 0
    left: 0
    border-radius: 4px
    outline: none
    border: none
    transform: translate3d(0,0,0)
    *
      z-index: 9999

  &__video-wrapper
    position: relative
    width: 100%
    //height: 226px
    margin-top: 16px
    break-inside: avoid-column
    @media (min-width: $tablet)
      height: 241px
      margin-top: 24px
    @media (min-width: $desktop-md)
      height: 213px
    @media (min-width: $desktop)
      height: 265px

  &__table
    width: 100%
    border-collapse: collapse
    @media (min-width: $tablet)
      display: none
    &--desktop
      display: none
      @media (min-width: $tablet)
        display: table
    &--links
      display: block
    th, td
      @include fontSLH(16px, 24px)
      border: 1px solid #CBCECF
      font-weight: 300
      padding: 8px 16px
    td
      text-align: right
    th
      border-left: none
      text-align: left
    tr:first-child
      th
        font-weight: 500
        text-align: right
        &.main-header
          font-family: MuseoSans, sans-serif
          font-weight: 900
      th:first-child
        text-align: left
      td, th
        border-top: none
        border-bottom: 1px solid #A2A7A8
    tr td:last-child, th:last-child
      border-right: none
    tr:last-child th, td
      border-bottom: none
    @media (min-width: $tablet)
      tr:first-child th:last-child
        display: flex
        flex-direction: column
        position: relative
        top: 1px

    .main-header
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, sans-serif
      font-weight: 900

    .headers
      @include fontSLH(16px, 24px)
    .text-right
      text-align: right !important
    .increase
      color: $green
      margin-left: 1px
    .decrease
      color: red
      margin-left: 1px
    .indentation
      margin-left: 15.33px

  &__links-table
    width: 100%
    td
      text-align: left
      border-left: none
    tr td:last-child
      border-right: none
    tr:last-child td
      border-bottom: none
    tr:first-child
      td, th
        border-top: none
        border-bottom: 1px solid #CBCECF
    td:first-child
      width: 42px
    a
      @include fontSLH(16px, 24px)
      text-decoration: underline

  &__links-headline
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, sans-serif
    font-weight: 900
    padding-bottom: 8px
    margin-bottom: 0

  &__links
    padding-left: 36px
    margin-bottom: 16px
    font-weight: 300
    li
      @include fontSLH(16px, 24px)
      margin-bottom: 6px
      a
        text-decoration: underline
        &:hover
          color: $green-hover
      &:last-child
        margin-bottom: 0

  &__table-wrapper
    border: 1px solid #CBCECF
    border-radius: 4px
    overflow: hidden
    margin-bottom: 16px

  &__price-table-description
    margin-bottom: 16px !important
    margin-left: -8px
    margin-right: -8px
    @media (min-width: $mobile)
      margin-left: 0
      margin-right: 0
    @media (max-width: 767px)
      padding: 0 !important

  &__change-headline
    white-space: nowrap

  &__price-left-part
    margin-bottom: 24px
    @media (min-width: $desktop)
      margin-bottom: 0

  &__most-viewed-overview-link a,
  &__price-full-overview a
    text-decoration: underline
    &:hover
      color: $green-hover

  &__most-viewed-overview-link
    display: flex
    flex-direction: column

  .youtube-player
    position: relative
    padding-bottom: 56.25%
    height: 100%
    overflow: hidden
    max-width: 100%
    background: #000
    border-radius: 4px

  .youtube-player iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 100
    background: transparent

  .youtube-player img
    object-fit: cover
    display: block
    left: 0
    bottom: 0
    margin: auto
    max-width: 100%
    width: 100%
    position: absolute
    right: 0
    top: 0
    border: none
    height: auto
    cursor: pointer
    -webkit-transition: 0.4s all
    -moz-transition: 0.4s all
    transition: 0.4s all

  .youtube-player .play
    font-size: 72px
    height: 48px
    width: 68px
    left: 50%
    top: 50%
    margin-left: -30px
    margin-top: -28px
    position: absolute
    color: #ff0102
    background: url('../../../src/images/icons/youtube.png') no-repeat
    background-size: contain
    cursor: pointer
    opacity: 0.85
    transition: all ease 0.3s
    &:hover
      background-image: url('../../../src/images/icons/youtube_red.png')
      opacity: 1

  .youtube-player img:hover ~ .play
    background-image: url('../../../src/images/icons/youtube_red.png')
    opacity: 1

// RATING
.lv-header
  &__rating-wrapper
    margin-bottom: 40px
    @include displayFlex(center)
    svg
      pointer-events: none

  &__rating-stars
    @include displayFlex(center)

  &__rating-star
    padding-right: 2px
    display: flex
    &:hover
      cursor: pointer
    &:last-child
      padding-right: 0
    svg
      fill: #FFA300

  &__rating-container
    @include displayFlex(center)
    justify-content: center
    flex-direction: column
    @media (min-width: $tablet)
      flex-direction: row

  &__rating-text, &__rating-headline
    @include font(16px, $default, 24px)

  &__rating-headline
    margin-bottom: 6px
    text-align: center
    display: flex
    justify-content: center
    @media (min-width: $tablet)
      margin-bottom: 0
      margin-right: 34px

  &__rating-text
    margin-left: 6px


