// MOBILE-FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.search-wrapper
  position: relative

.main-search-wrapper
  display: flex

.location-search-input
  @include fullWidthSize(41px)
  font-size: 14px
  padding: 10px 36px 10px 10px
  border: 1px solid $border-form-input
  outline: none
  border-radius: 5px
  border-top-left-radius: 0
  border-bottom-left-radius: 0
  &:focus
    border-color: $green

.on-list-view::-webkit-credentials-auto-fill-button, ::-webkit-contacts-auto-fill-button
  visibility: hidden
  display: none !important
  pointer-events: none
  position: absolute
  right: 0

.on-list-view
  border-radius: 0

.location-search-clear-btn
  @include positionAbsolute(30%, 13px)
  cursor: pointer
  color: $link-list-view-hover
  opacity: 0.7
  padding-top: 3px
  &:hover
    opacity: 1
  svg
    display: block
  @media (min-width: $desktop)
    top: 25%

.location-search-empty-result
  background-color: $green
  color: $white
  font-size: 1em
  padding: 7px 10px 9px

.location-search-label
  background-color: #eeeeee
  color: #888888
  padding: 0.5em
  text-transform: uppercase
  font-size: 0.85em

.location-search-item
  background-color: $white
  color: #777777
  display: flex
  justify-content: space-between
  padding: 0.3em
  font-size: 14px
  &:hover
    background-color: $bg-location-search
    color: $bg-charcoal
    text-decoration: underline
  &.selected
    background-color: $bg-location-search
    color: $bg-charcoal
    text-decoration: none
    &:hover
      text-decoration: underline
  &:not(:first-child)
    border-top: 1px solid $border-grey
  & span
    display: block
    &:first-of-type
      flex-basis: 60%
    &:last-of-type
      color: #777777
      flex-basis: 40%
      text-align: right

.search-result-container
  margin-top: 41px
  position: absolute
  width: 100%
  overflow: auto
  z-index: 10
  left: 0
  @media (min-width: $screen-md)
    max-height: calc(100vh - 325px)

.search-btn
  @include positionAbsolute(0, 0)
  @include boxSize(40px, 44px)
  text-align: center
  svg
    position: absolute
    top: 14px
    left: 10px
