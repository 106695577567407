// MOBILE-FIRST
@import "../../src/stylesheets/variables"
@import '../../src/stylesheets/mixins'

.list-view
  .popular-areas
    padding-top: 8px
    @media (min-width: $tablet)
      padding-top: 0
    @media (min-width: $desktop-md)
      margin-bottom: 64px

  .skyline-row
    background-color: $grey-bg
    div
      @include fullWidthSize(70px)
      position: relative
      background: center repeat-x

  &__list-as-list
    margin-top: 24px

  .FAQSection
    @media (max-width: $mobile)
      padding-top: 30px
      padding-bottom: 23px

  .search-agent-section
    padding-top: 30px
    padding-bottom: 32px
    @media (min-width: $tablet)
      padding-top: 64px
      padding-bottom: 64px
    @media (min-width: $desktop)
      padding-top: 57px

  @media (width: $tablet)
    .search-agent-section__content-wrapper
      align-items: flex-start !important
    .search-agent-section__buttons-block.Dropdown-placeholder
      padding-top: 5px

  .Dropdown-arrow-wrapper
    margin-left: 8px
    height: 24px
    pointer-events: none
    display: flex
    svg
      @include maxSize(24px, 24px)

  .Dropdown-menu
    width: auto
    border-radius: 4px
    box-shadow: 0 6px 15px 0 rgba(0,4,10,0.15)
    border: none
    top: 32px

  .Dropdown-control:hover
    box-shadow: none

  .spinner-wrapper
    margin-bottom: 300px
    padding-top: 50px
    @media (min-width: $tablet)
      padding-top: 100px
    &.order-form-spinner
      position: fixed
      height: 100vh
      width: 100vw
      top: 0
      left: 0
      padding: 0
      background: rgba(0,0,0,0.6)
      z-index: 9999

  .space-calculator
    margin-left: 8px
    @media (min-width: 767px) and (max-width: 991px)
      margin-right: 8px
    &__toggle
      height: 40px
      border-radius: 8px
      border: 1px solid rgba(119, 188, 31, 0.20)
      padding: 0 16px
      color: $green
      &:hover
        border-color: $green-hover
        color: $green-hover
        svg
          fill: $green-hover
      svg
        fill: $green
        @media (max-width: 991px)
          margin-right: 0
      @media (min-width: $desktop-md)
        height: 44px

    &__toggle-label
      display: none
      @media (min-width: $desktop-md)
        @include fontSLH(16px, 24px)
        display: block
    &.mobile-hidden
      @media (max-width: 767px)
        display: none
    &.mobile
      margin-bottom: 8px
      margin-left: 0
      @media (min-width: $tablet)
        display: none
      .space-calculator__toggle
        width: 100%
        justify-content: flex-start
        background: white

.container.max-width,
.container.top-menu__container,
.breadcrumbs .container
  @media (min-width: $desktop-md)
    width: calc(100vw - 64px) !important
  @media (min-width: $desktop)
    width: calc(100vw - 96px) !important


footer
  padding-bottom: 80px
