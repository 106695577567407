// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.list-view-container
  background: $grey-bg
  padding-bottom: 32px

.sort-bar
  background: #F4F5F5
  padding-top: 20px
  padding-bottom: 4px
  @media (min-width: $tablet)
    padding-bottom: 0

  &__layout-switcher
    @include displayFlex(center)
    height: 44px
    gap: 4px
    border-radius: 8px
    border: 1px solid #D7DADA
    padding: 3px
    margin-right: 16px
    &:hover
      border-color: $green

  &__layout-switcher-pill
    @include square(36px)
    @include displayFlex(center, center)
    border-radius: 5px
    svg
      fill: $black
      fill-opacity: 0.5
    &.active
      background: $green
      svg
        fill: $white
        fill-opacity: 1
    &:not(.active)
      cursor: pointer
      &:hover svg
        fill: $green
        fill-opacity: 1

  &__locations-count, .lv-filter__filters-toggle
    @media (min-width: $tablet)
      display: none

  &__locations-count
    @include fontSLH(14px, 24px)

  &__sorting-wrapper
    display: flex
    align-items: center
    width: 100%
    @media (max-width: 991px)
      justify-content: space-between

  &__sorting-wrapper-divider
    display: none
    @media (min-width: $desktop-md)
      display: block
      height: 24px
      border-right: 1px solid #D7DADA
      margin: 0 24px

  .lv-filter__filters-toggle
    margin-left: 0
    width: calc(50% - 4px)
    min-width: calc(50% - 4px)
    background: white

  &__main-wrapper
    display: block
    position: relative
    @media (min-width: $tablet)
      @include displayFlex(center, space-between)

  &__right-part
    @include displayFlex(center)
    width: 100%
    justify-content: space-between
    @media (max-width: $mobile)
      flex-wrap: wrap

  &__facilities-filter-wrapper
    display: none
    @media (min-width: $desktop-md)
      display: block

  &__dropdown-wrapper
    display: flex
    justify-content: space-between
    @media (max-width: 991px)
      width: 100%

  &__sort-type-wrapper
    @include displayFlex(center)
    flex-wrap: nowrap
    @media (min-width: $desktop-md)
      &:hover
        svg
          fill: $green-hover
        .sort-by__control p,
        .sort-by__single-value
          color: $green-hover

  &__sort-type-icon
    min-width: 24px
    margin-right: 8px

  &__per-page-wrapper
    @include displayFlex(center)
    margin-left: auto

  &__left-part
    align-items: center
    display: none
    @media (min-width: $tablet)
      display: flex

  &__map-toggle
    @include displayFlex(center)
    height: 44px
    border-radius: 8px
    border: 1px solid #D7DADA
    padding: 0 16px
    cursor: pointer
    label
      @include displayFlex(center)
      gap: 8px
      cursor: pointer
    &:hover
      border-color: $green
    &.on
      border: none
      background: linear-gradient(73deg, #8ACB36 0%, #6FB516 100%)
      .sort-bar__map-toggle-label
        color: $white
    @media (max-width: 991px)
      display: none

  &__map-toggle-label
    @include fontSLH(16px, 24px)

  &__facilities-dropdown
    @include displayFlex(center, space-between)
    background: $white
    height: 44px
    border: 1px solid $border-form-input
    padding: 0 10px
    margin-right: 24px
    cursor: pointer
    span
      @include fontSLH(14px, 21px)
      font-family: MuseoSans, sans-serif
      margin-right: 25px
    i
      font-size: 18px
      color: $inactive
      &.active
        color: $default

  &__facilities-filter-list
    @include positionAbsolute(54px, 0)
    display: none
    transition: all .6s
    background: white
    box-shadow: 0 1px 4px 0 rgba(49,60,63,0.25)
    width: 100%
    z-index: 999
    padding: 24px
    &.open
      display: block

  &__facilities-select-links
    display: flex
    margin-bottom: 24px
    div
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, sans-serif
      cursor: pointer

  &__facilities-select-all-link
    margin-right: 24px
    &:hover
      color: $green

  &__facilities-deselect-all-link
    &:hover
      color: $red

  &__facilities-headline
    @include font(16px, $default, 24px, bold)
    margin-bottom: 16px
    &.facilities
      margin-top: 8px

  &__facilities-list,
  &__suitable-for-list
    display: flex
    flex-wrap: wrap

  &__facilities-filter-item
    display: block
    width: 33.333%
    label
      @include font(16px, 24px)
      font-family: MuseoSans, sans-serif
      display: inline-flex
      align-items: center
      cursor: pointer
      margin-bottom: 16px
      input
        @include square(10px)
        margin-right: 20px
        &:before
          @include square(20px)
          background: white
          border: 1px solid $border-form-input
          border-radius: 2px
          top: -5px
          position: relative
          display: flex
          justify-content: center
        &:checked:before
          background: $green
          border-color: $green

  .__react_component_tooltip
    font-size: 12px
    font-weight: 100
    padding: 3px 5px

  .sort-by__indicator
    display: none

  .per-page, .sort-by
    @include displayFlex(center)
    height: 24px
    &:focus-visible
      outline: none
    @media (min-width: $tablet)
      height: 44px

    .react-select-2-input
      display: none

    &__placeholder
      max-width: 19px
      overflow: hidden
      height: 24px
      font-weight: 300

    &__indicator-separator
      display: none

    &__dropdown-indicator
      padding-right: 0

    &__value-container
      height: 24px

    &__single-value
      margin: 0
      font-weight: 300
      color: $default
      position: relative
      top: unset
      transform: translate(0, 0)
      max-width: unset

    .sort-by__single-value
      text-overflow: ellipsis
      overflow: hidden
      display: -webkit-box
      -webkit-line-clamp: 1
      -webkit-box-orient: vertical
      white-space: initial
      word-break: break-all
      font-weight: 500

    .per-page__single-value
      text-overflow: unset
      overflow: unset

    .per-page__single-value
      max-width: 19px
      width: 19px

    &__value-container
      padding: 0
      input
        position: absolute

    &__dropdown-indicator svg
      fill: $default

    &__control
      @include font(16px, $default, 24px)
      border: none
      cursor: pointer
      padding: 0
      background: transparent
      display: flex
      flex-wrap: nowrap
      box-shadow: none
      max-width: 290px
      &:hover
        @media (min-width: $desktop-md)
          color: $green-hover
          .per-page, .sort-by
            &__single-value
              color: $green-hover
          svg
            fill: $green-hover
      &--menu-is-open
        .per-page, .sort-by
          &__dropdown-indicator svg
            transform: rotate(180deg) scaleX(-1)

      p
        display: none
        margin: 0
        white-space: nowrap
        @media (min-width: $tablet)
          @include fontSLH(16px, 24px)
          font-family: MuseoSans, sans-serif
          display: flex
          margin-right: 8px

    &__option
      @include font(16px, $default, 24px)
      padding: 8px 16px
      white-space: nowrap
      &:hover
        background: $grey-bg
        cursor: pointer
      &--is-focused
        background: $white
      &--is-selected
        background: $grey-bg
        color: $default

    &__menu
      z-index: 101
      right: 0
      min-width: 135px
      margin: 0
      top: 32px
      overflow: hidden
      @media (min-width: $tablet)
        top: 45px

    .sort-by__menu
      min-width: 100%
      width: max-content
      max-width: calc(100vw - 32px)
      @media (max-width: 767px)
        left: -32px
        top: 35px

    .sort-by__option
      white-space: unset

    &__menu-list
      padding: 0
      max-height: 500px

  &__mobile-map-button
    background-color: $green
    display: flex
    align-items: center
    height: 40px
    justify-content: center
    padding: 0 16px
    border-radius: 8px
    margin-left: 8px
    @media (min-width: $tablet)
      height: 44px
    @media (min-width: $desktop-md)
      display: none
    .map-text
      color: $white
      margin-bottom: 0
      display: flex
      align-items: center
    svg
      fill: $white
      margin-right: 8px
    &:hover
      background-color: $green-hover
      @media (max-width: 768px)
        background-color: $green

