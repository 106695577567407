// MOBILE-FIRST
@import "../../../src/stylesheets/variables"
@import '../../../src/stylesheets/mixins'

.list-view-container
  @include displayFlex(stretch, center)

.list-view-main-content
  @media (max-width: $tablet)
    overflow: hidden

.list-view-main-content.with-map
  padding-right: 0px !important
  padding-left: 48px
  @media (min-width: $desktop)
    padding-left: 64px

.skeleton-box-wrapper
  height: 100%
  .skeleton-box
    @include square(100%)
    background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 60%) $grey-bg
    background-size: 200% 100%
    background-position-x: 180%
    animation: 0.7s loading ease-in-out infinite
    border-radius: 0px
    margin: 4px 0

  @keyframes loading
    to
      background-position-x: -20%

.list-view-map
  @media (max-width: $tablet)
    @include fullWidthSize(calc(100% - 70px))
    position: fixed
    top: 70px
    left: 0
    z-index: 1000
    padding: 0
  @media (min-width: $desktop-md)
    padding-top: 16px
    .main-map
      position: sticky !important
      height: calc(100vh - 72px) !important
      top: 74px
      z-index: 1
      border-top-left-radius: 4px
      border-bottom-left-radius: 4px
  &.hidden
    visibility: hidden

  &--item-list-location
    height: 100%
    top: 0
    z-index: 2000

.no-locations-found
  background: $grey-bg
  padding: 100px 15%
  font-size: 1.5em
  text-align: center
  position: relative
  top: calc(50% - 100px)
  &.map-open
    top: 0

.server-error-message
  margin-top: 38px
  margin-bottom: 24px
  background: rgba(255,90,90,0.09)
  padding: 24px
  @include fontSLH(16px, 24px)
  font-family: MuseoSans, Helvetica, Arial, sans-serif
  display: flex
  align-items: center
  border-radius: 4px
  @media (max-width: $tablet)
    margin-top: 0
    padding: 18px
  @media (max-width: $mobile)
    margin-bottom: 16px
  img
    margin-right: 18px
  a
    text-decoration: underline
  p
    margin-bottom: 0
.not-showing
  display: none

.no-found-text
  text-align: left
  padding: 64px 0
  margin-bottom: 0
  @media (max-width: $mobile)
    padding: 34px 32px
  .title
    @include fontSLH(16px, 24px)
    font-weight: 900
    margin-bottom: 8px
    @media (max-width: $mobile)
      margin-bottom: 16px
  .description
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, Helvetica, Arial, sans-serif
    a
      text-decoration: underline

.pagination-wrapper
  display: flex
  justify-content: center
  position: relative
  .pagination
    margin: 0
    display: flex
    align-items: center
    padding-left: 0
    list-style: none
    border-radius: 0.25rem
    li
      @include displayFlex(center, center)
      a
        @include square(40px)
        @include displayFlex(center, center)
        text-decoration: none
        color: $default
        cursor: pointer
        outline: none
        font-size: 14px
        font-weight: 300
        margin: 0 1px
        border-radius: 40px
        background: $white
        border: 1px solid $borders
        &:hover
          font-weight: 900
          background: #F6FDE8
          border: 1px solid $green
    .active
      a
        border: 1px solid $green
        background: $green
        outline: none
        font-weight: 300
        cursor: default
        color: $white
        &:hover
          font-weight: 900
          background: $green
          color: $white
          font-weight: 300
      & + li a
        background: #F6FDE8
        border: 1px solid $green
    .next
      a
        margin-left: 1px
        svg
          fill: $green
    .previous
      a
        margin-right: 1px
    .disabled
      display: none
    .break a
      border: none !important
      background: none !important
      &:hover
        border: none
        background: none

.bottom-pagination
  position: sticky
  bottom: 0
  position: fixed
  bottom: 0
  left: 0
  width: 100vw
  z-index: 9999999991
  background: white
  min-width: 100vw
  padding: 16px
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.10)

.visible-favorite-location
  margin-bottom: 64px

.list-view-round-loader-wrapper
  background: $grey-bg

.smoothie-spinner
  @include square(60px)
  margin: 0 auto
  background: $green
  border-radius: 50%
  animation: boing .8s linear infinite
  position: relative
  top: calc(50% - 35px)
  &:before
    @include beforeEmptySquare(56px, $light-green, calc(50% - 28px), $left: calc(50% - 28px))
    display: block
    border-radius: 50%
    animation: boing-inner .8s linear infinite

  &:after
    @include beforeEmptySquare(52px, $white, calc(50% - 26px), $left: calc(50% - 26px))
    display: block
    border-radius: 50%
    animation: boing-inner-2 .8s linear infinite

@keyframes boing
  0%
    transform: scale(1)
  25%
    transform: scale(.75)
  100%
    transform: scale(1)

@keyframes boing-inner
  0%
    transform: scale(1)
  25%
    transform: scale(.6)
  100%
    transform: scale(1)

@keyframes boing-inner-2
  0%
    transform: scale(1)
  10%
    transform: scale(.1)
  100%
    transform: scale(1)


.policy-block
  @media (max-width: $screen-xs-max)
    bottom: 175px


.notification
  top: auto !important
  bottom: 100px
  left: 50% !important
  width: 320px
  //z-index: 700 !important
  transform: translate(-50%, 0)
  &.with-policy-block
    @media (max-width: $screen-xs-max)
      bottom: 175px

.list-item__energy-rating
  @include displayFlex(center)
  @include fontSLH(12px, 24px)
  &.a2010
    color: #47B748
  &.a2015
    color: #00AD64
  &.a2020
    color: #00996B
  &.a
    color: #22AB72
  &.b
    color: #7BB12A
  &.c
    color: #DBA600
  &.d
    color: #EF8514
  &.e
    color: #E53E1A
  &.f
    color: #9C181E
  &.g
    color: #8A1925
  svg
    pointer-events: none
    margin-left: 4px

.list-item__energy-rating-wrapper
  position: absolute
  right: -10px
  top: -10px
  height: 38px
  width: 38px
  display: flex
  align-items: center
  justify-content: center

.embla-list-item-slider,
.embla-grid-item-slider
  .embla
    &__buttons
      @include displayFlex(center, space-between)
    &__controls
      position: absolute
      bottom: 16px
      width: 100%
      left: 0
      padding: 0 16px
    &__button
      @include square(24px)
      @include displayFlex(center, center)
      border-radius: 4px
      background: rgba(255, 255, 255, 0.8)
      transition: all .25s ease-in
      svg
        max-width: 24px
        max-height: 24px
        pointer-events: none

.embla-list-item-slider .embla__controls
  width: calc(100% - 8px)

.info-badges
  &__new-special-wrapper
    flex-direction: column
    top: 16px
    left: 16px

  .new-badge, .special-offer-badge
    padding: 6px
    width: fit-content
    margin-bottom: 8px

.grid-view-item-loader
  background-color: $white
  display: block
  position: relative
  border: 1px solid $borders
  border-radius: 4px
  .skeleton-box
    &--1
      height: 233px
      margin: 0
    &--2
      margin: 10px 0 8px
    &--2, &--4
      height: 21px
    &--3
      height: 24px
    &--4
      margin: 5px 0 10px
    &--5
      height: 1px
      margin-bottom: 10px
    &--6, &--8, &--10
      height: 14px
      margin-bottom: 4px
    &--7,  &--9, &--11
      height: 18px
      margin-bottom: 12px
    &--12
      height: 36px
      margin-bottom: 16px
    &--2, &--3, &--4, &--5, &--6, &--7, &--8, &--9, &--10, &--11, &--12
      margin-right: 16px
      margin-left: 16px
      border-radius: 8px
      width: auto

.grid-view-item-loader-wrapper
  margin-bottom: 20px
  overflow: hidden
  @media (min-width: $tablet)
    height: 542px
    min-height: 542px
  @media (min-width: $desktop-md)
    margin-bottom: 16px
  @media (max-width: $screen-xs-max)
    height: auto
    margin-left: auto
    margin-right: auto

.list-item__wrapper
  @include displayFlex(false, space-between)
  position: relative
  background: $white
  margin: 16px 0
  height: 223px
  border-radius: 4px
  -webkit-tap-highlight-color: transparent
  &.row
    margin-left: 0 !important
    margin-right: 0 !important
  &.skeleton
    border: 1px solid $bg-badge-rented-out

.list-item__image-slider
  width: 71px
  border-top-left-radius: 4px
  border-bottom-left-radius: 4px
  padding-left: 0 !important
  @media (min-width: $tablet)
    border-top-left-radius: 4px
    border-bottom-left-radius: 4px
    &.skeleton
      height: 221px
  .skeleton-box
    margin: 0

.list-item__description-wrapper
  @include displayFlex(false, $flexDirection: column)
  padding: 16px
  flex: 1 0
  position: relative
  @media (min-width: $screen-sm) and (max-width: $screen-lg-md-max)
    .description-line
      margin-top: -7px
  @media (max-width: $screen-xs-max)
    @include truncate()
    padding-top: 0 !important
    padding-bottom: 0 !important
  .skeleton-box
    border-radius: 8px
    padding: 0 16px
    margin-top: 0
    &--1, &--2
      height: 24px
      margin-bottom: 8px
    &--3, &--4, &--5, &--6
      height: 22px
    &--3, &--4, &--5
      margin-bottom: 2px
    &--6
      margin-top: 30px
      margin-bottom: 0

.list-item__metrics-wrapper
  @include displayFlex(flex-start, space-between, column)
  padding: 16px 8px
  position: relative
  border: 1px solid $bg-badge-rented-out
  border-left: none
  border-top-right-radius: 4px
  border-bottom-right-radius: 4px
  &.skeleton:before
    display: none
  .skeleton-box-wrapper
    width: calc(100% - 16px)
  .skeleton-box
    border-radius: 8px
    padding: 0 24px
    margin-top: 0
    &--1, &--3, &--5
      height: 14px
      margin-bottom: 2px
    &--2, &--4, &--6
      height: 17px
      margin-bottom: 12px
    &--7
      height: 36px
      margin-top: 30px
      margin-bottom: 0

.grid-list
  margin-top: 16px
