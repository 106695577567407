// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

$component: '.lv'
$topPosition: '.main-filter-top'
$mediumContainerIndentation: calc((100vw - 736px) / 2)

// LIST VIEW MAIN FILTER


.main-filter
  &--top
    @media (max-width: $tablet)
      position: absolute
      width: 100%
      top: 0
      z-index: 2000
      .sticky-outer-wrapper
        position: fixed
        width: 100%

.lv-filter
  position: relative
  padding: 0

  &__filters-toggle-wrapper
    @include displayFlex(center)
    width: 100%

  &__row
    @media (max-width: 767px)
      min-width: calc(100% - 32px)

  .no-search-results
    padding: 4px 16px 12px
    &__header
      @include displayFlex(center)
      svg
        fill: #77BC1F
        margin-right: 8px
      p
        font-weight: 900
        margin-bottom: 0
    &__subheader
      font-size: 14px
      font-weight: 300

  &__modal
    @media (min-width: $desktop-md)
      position: fixed
      height: 100vh
      width: 100vw
      background: rgba(0,0,0,0.6)
      display: flex
      align-items: center
      justify-content: center
      backdrop-filter: blur(5px)
      z-index: 2000
      top: 0
      left: 0
    .filter-toggle
      font-family: MuseoSans, sans-serif
      padding: 0

  &__right-part
    @include displayFlex(center, flex-start)
    @media (min-width: $desktop-md)
      width: 100%

  &__modal-container
    @media (min-width: $desktop-md)
      width: 100%
      max-width: 832px
      border-radius: 16px
      position: relative

  &__filters-wrapper
    display: flex
    background: $white
    z-index: 1111
    @media (min-width: $desktop-md)
      flex-direction: column
      width: 100%
      border-bottom-right-radius: 16px
      border-bottom-left-radius: 16px
      overflow-y: scroll
      overflow-x: hidden
      max-height: calc(100vh - 132px)
      position: relative
      scroll-behavior: smooth
      height: 100%
    @media (max-width: $screen-lg-md-max)
      display: none
      position: fixed
      width: 100vw
      min-height: 100vh
      top: 0
      left: 0
      height: 100vh
    &.mobile-active
      display: block
      overflow: auto
    &.with-button
      padding-bottom: 68px
      @media (min-width: $tablet)
        padding-bottom: 100px

  .dropdown-selector__placeholder
    margin: 0

  .dropdown-selector__trigger
    line-height: 24px
    height: 55px
    display: flex
    align-items: center
    margin: 0 16px
    @media (min-width: $tablet)
      margin: 0 24px
    @media (min-width: $from-tablet)
      margin: 0 $mediumContainerIndentation
    @media (min-width: $desktop-md)
      margin: 0
      height: 44px
      &:hover
        border-bottom: 1px solid $green

  &__filters-toggle
    @include displayFlex(center, space-between)
    min-width: 125px
    border: 1px solid $bg-badge-rented-out
    border-radius: 8px
    padding: 7px 10px 7px 16px
    color: $default
    cursor: pointer
    background: #F6F7F7
    width: 100%
    &:hover
      border: 1px solid $green
    @media (min-width: $tablet)
      margin-left: 16px
      margin-right: 8px
      width: auto
      padding: 9px 10px 9px 16px
    p
      font-family: MuseoSans, sans-serif
      margin: 0 10px
      white-space: nowrap
    &.desktop
      @media (max-width: 767px)
        &:not(.visible)
          display: none

  &__mobile-toggle-left
    @include displayFlex(center)
    svg
      @include maxSize(18px, 18px)

  &__modal-header
    @include displayFlex(center, center)
    position: fixed
    top: 0
    right: 0
    width: 100vw
    height: 56px
    border-bottom: 1px solid $bg-badge-rented-out
    background: $white
    padding: 16px 0
    z-index: 2222
    svg
      @include positionAbsolute(16px)
      left: 16px
      cursor: pointer
      @media (min-width: $tablet)
        left: 24px
      @media (min-width: $from-tablet)
        left: $mediumContainerIndentation
      @media (min-width: $desktop-md)
        right: 24px
        left: unset
    @media (min-width: $desktop-md)
      position: relative
      width: 100%
      height: 68px
      z-index: 1111
      border-top-right-radius: 16px
      border-top-left-radius: 16px

  &__modal-headline
    @include fontSLH(20px, 24px)
    @media (min-width: $desktop-md)
      font-weight: 900

  &__clear-filters-link
    @include fontSLH(14px, 24px)
    font-family: MuseoSans, sans-serif
    position: absolute
    right: 16px
    top: 16px
    text-decoration: underline
    cursor: pointer
    &:hover
      color: $green-hover
    @media (min-width: $tablet)
      right: 24px
    @media (min-width: $from-tablet)
      right: $mediumContainerIndentation
    @media (min-width: $desktop-md)
      display: none

  &__search-agent-link-wrapper
    margin: 32px 16px
    @media (min-width: $tablet)
      margin: 32px 24px
    @media (min-width: $from-tablet)
      margin: 32px $mediumContainerIndentation

  &__search-agent-link
    @include displayFlex(flex-start, space-between)
    @include fontSLH(16px, 19px)
    font-family: MuseoSans, sans-serif
    color: $green
    text-decoration: none
    position: relative
    @media (min-width: $tablet)
      justify-content: flex-start
    &:hover
      color: $green-hover
      text-decoration: none
    span
      text-decoration: underline
    svg
      fill: $green
      margin-left: 8px
      position: relative
      top: 3px

  &__button-wrapper
    @include displayFlex(center, center)
    position: fixed
    bottom: 0
    left: 0
    height: 68px
    border-radius: 4px 4px 0 0
    box-shadow: 0 -2px 10px 0 rgba(26,34,109,0.1)
    z-index: 1000
    background: $white
    width: 100vw

  &__search-button
    @include displayFlex(center, center)
    @include font(16px, $white, 24px, 900)
    background-color: $green
    padding: 12px 30px
    min-width: 280px
    border-radius: 4px
    cursor: pointer
    svg
      margin-right: 8px
      fill: white
    &:hover
      background: $green-hover


#{$component}-filter-wrapper
  height: 104px
  border: none
  padding: 8px 0
  @media (min-width: $tablet)
    @include displayFlex(center, center)
    height: 76px
    padding: 0
  .dropdown-selector__item-name
    font-family: MuseoSans, sans-serif
    padding: 0

#{$component}-filter__container
  @include displayFlex(center)
  box-shadow: 0 3px 4px -1px rgba(49,60,63,0.25)
  background-color: $white
  height: 104px
  @media (min-width: $tablet)
    height: 76px
  @media (min-width: $desktop-md)
    box-shadow: 0 1px 4px 0 rgba(49,60,63,0.25)
  & > .container
    @media (max-width: 767px)
      width: 100%
  &.map
    @media (max-width: 767px)
      height: 112px
      .lv-filter-wrapper
        display: block
      .lv-filter__location-search
        margin-bottom: 8px
      .lv-filter-wrapper
        height: auto
      .sort-bar__mobile-map-button,
      .lv-filter__location-search .search-wrapper input
        height: 40px
      .lv-filter__filters-toggle
        padding: 7px 10px 7px 16px
        margin-left: 0


#{$component}-filter__location-search
  flex-grow: 1
  @media (min-width: $desktop-md)
    max-width: 444px
    margin-right: 16px
    width: 100%
  @media (max-width: 767px)
    margin-bottom: 8px
  .search-wrapper
    @media (min-width: $desktop-md)
      max-width: 444px
    input
      height: 40px
      background: #F6F7F7
      &::-webkit-input-placeholder
        line-height: normal
        color: #c9c9c9
      @media (min-width: $tablet)
        height: 44px

  .search-btn
    left: 0
    width: 36px
    pointer-events: none
    &:after
      width: 16px
      max-width: 16px
      font-size: 16px
      color: $default

  .error-text
    color: $red

  .error-wrapper
    @include fontSLH(14px, 24px)
    font-family: MuseoSans, sans-serif
    padding: 16px
    border-radius: 4px
    background-color: $white
    animation-name: goto_pulse_animation
    animation-duration: 1000ms
    animation-iteration-count: infinite
    animation-timing-function: ease-in-out
    position: absolute
    top: 44px
    z-index: 10
    width: calc(100vw - 32px)
    box-shadow: 0 6px 15px 0 rgba(0,4,10,0.15)
    @media (min-width: $tablet)
      padding: 24px
      width: 658px
    @media (min-width: $desktop-md)
      width: 898px

  .location-search-input
    @include font(16px, $default, 24px)
    font-family: MuseoSans, sans-serif
    border: 1px solid $bg-badge-rented-out
    border-radius: 8px
    padding: 10px 10px 10px 36px
    position: relative
    &:hover, &:active, &:focus
      border: 1px solid $green
    &::-ms-clear
      display: none

    &[placeholder]
      @include fontSLH(16px, 24px)
      position: relative
      text-overflow: ellipsis
      display: block
    &::placeholder
      font-family: MuseoSans, sans-serif
      color: $dark-borders !important
    &.filled
      padding-right: 36px

  .location-search-clear-btn
    padding-top: 0
    color: $default
    height: 16px
    top: 14px
    i
      color: $default

  .search-result-container
    width: calc(100vw - 32px)
    box-shadow: 0 6px 15px 0 rgba(0,4,10,0.15)
    padding: 8px 8px
    border-radius: 8px
    background: $white
    margin-top: 44px
    max-height: 2000vh
    @media (min-width: $tablet)
      padding: 16px 8px 16px 24px
      width: 658px
    @media (min-width: $desktop-md)
      width: 898px
      padding: 16px 24px
    & > div
      margin-bottom: 8px
      padding-bottom: 8px
      border-bottom: 1px solid $bg-badge-rented-out
      @media (min-width: $tablet)
        margin-bottom: 16px
        padding-bottom: 16px
      &:last-child
        margin: 0
        padding: 0
        border-bottom: none

  .location-search-label
    @include font(12px, $gull-grey-text, 20px)
    font-family: MuseoSans, sans-serif
    font-weight: 900
    background: $white
    text-transform: uppercase
    padding: 8px 8px 0
    margin-bottom: 8px
    @media (min-width: $tablet)
      padding: 8px 0 0
    @media (min-width: $desktop-md)
      @include font(14px, 24px)
      margin-bottom: 16px

  .location-search-item
    @include font(14px, $default, 24px)
    font-family: MuseoSans, sans-serif
    border-radius: 8px
    padding: 8px
    &.selected
      background: $white
      &:hover
        background: $bg-location-search
    &:not(:first-child)
      border-top: none
    &:hover
      text-decoration: none
    span:first-of-type
      padding-right: 16px
      @media (min-width: $tablet)
        flex-basis: 50%
      @media (min-width: $desktop-md)
        font-size: 16px
    span:last-of-type
      @include font(16px, $default, 24px)

#{$component}-filter__price
  .input-range__container
    margin-top: 0
  @media (min-width: $desktop-md)
    .filter-toggle
      cursor: default
    .dropdown-selector__body
      width: 636px
      margin: auto
      padding: 24px 5px 20px
    .form-group
      @include boxSize(260px, 56px)
      border: 1px solid $bg-badge-rented-out
      border-radius: 4px
      padding-bottom: 8px
      flex-direction: row-reverse
      justify-content: flex-end
      align-items: flex-end
      cursor: text
      input,
      .input-range__dimension
        @include fontSLH(16px !important, 19px !important)
      input
        border: none !important
        padding: 26px 0 0 0 !important
        margin: 0 0 0 5px !important
        width: 100% !important
        height: 45px !important
        &:not(.outline-none)
          &:focus ~ .input-range__input-border
            border-color: $green !important
      &:first-child:after
        content: ''
        width: 36px
        height: 2px
        background: $bg-badge-rented-out
        right: -73px
        position: absolute
        bottom: 26px
    .input-range
      &__label
        display: none
      &__input-label
        @include font(14px, $gull-grey-text, 17px, 300)
        @include positionAbsolute(8px, $left: 8px)
      &__dimension
        padding-left: 8px !important
      &__input-border
        position: absolute
        height: calc(100% + 2px)
        width: calc(100% + 2px)
        top: -1px
        left: -1px
        border-radius: 4px
        pointer-events: none
        border: 1px solid transparent

#{$component}-filter__meters
  .input-range__container
    margin-top: 12px
  .dropdown-price__body
    padding: 0
  .filter-toggle__icon-wrapper svg
    position: relative
    top: -3px
  @media (min-width: $desktop-md)
    .filter-toggle
      cursor: default
    .lv-filter__meters-section
      width: 100%
    .dropdown-selector__body,
    .dropdown-selector__body--open
      display: flex !important
      flex-direction: row !important
    .dropdown-selector__body
      padding: 24px 42px 56px !important
    .noUi-target
      left: -5px
    .input-range__hint
      @include font(14px !important, $gull-grey-text, 24px)
      position: absolute
      bottom: 23px
    .input-range__label
      margin-bottom: 16px !important

#{$component}-filter__meters-section--meters
  .input-range__container
    border-bottom: none !important
  @media (min-width: $desktop-md)
    margin-right: 80px

#{$component}-filter__price,
#{$component}-filter__meters
  @include displayFlex(normal, center, column)
  padding: 0
  min-height: 55px
  @media (min-width: $desktop-md)
    min-height: unset
    border-bottom: 1px solid $bg-badge-rented-out
    .dropdown-selector__body
      position: relative
      box-shadow: none
    .dropdown-selector
      height: auto
    .dropdown-selector__trigger
      cursor: default
      height: auto
      padding: 20px 24px 0 24px
      &:hover,
      &--active
        border: none
    .dropdown-selector
      &__action-icon
        display: none
      &__placeholder
        @include fontSLH(20px, 24px)
        font-weight: 900

  .filter-toggle__icon-wrapper svg
    fill: $default
    margin-right: 8px
    @media (min-width: $desktop-md)
      display: none

  p
    margin: 0

  .dropdown-selector__selected-tooltip
    text-transform: none
    max-width: 100%

  .input-range__label
    @include fontSLH(14px, 20px)
    text-transform: capitalize
    margin-bottom: 8px

  .input-range__track
    height: 5px
    background: $dropdown-border
    box-shadow: none
    border-radius: 2px
    &--active
      background: $green

  .input-range__slider
    @include square(26px)
    background-color: $white
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.5)
    border-radius: 100%
    @media (min-width: $desktop )
      @include square(21px)
    &::after
      display: none

  .input-range__hint
    @include fontSLH(16px, 24px)
    font-weight: 300
    margin-top: 8px

  .input-range__container
    margin-bottom: 0
    border-bottom: 1px solid $bg-badge-rented-out
    padding-bottom: 24px
    width: 100%
    @media (min-width: $from-tablet)
      padding-left: 8px
    @media (min-width: $desktop-md)
      border-bottom: none
      padding: 0

  .input-range__dimension
    @include font(14px, false, 24px)
    display: inline-block
    text-transform: lowercase
    padding-left: 4px

  .input-range__inputs
    .form-group
      margin-bottom: 0
      input
        font-size: 12px
        height: 40px
        width: 75px
        border: 1px solid $bg-badge-rented-out
        border-radius: 4px
        padding: 6px 0 6px 11px
        text-align: left
        margin-right: 2px
        color: $default
        &:focus
          outline: none
          box-shadow: none
        &[type=number]
          -moz-appearance: textfield
          &::-webkit-inner-spin-button
            -webkit-appearance: none
        @media (min-width: $desktop )
          height: 28px

  .dropdown-selector__body
    background: $white
    padding: 0 16px
    display: flex
    flex-direction: column
    @media (min-width: $tablet)
      padding: 0 24px
    @media (min-width: $from-tablet)
      padding: 0 $mediumContainerIndentation
    @media (min-width: $desktop-md)
      display: none
      padding: 24px 5px 20px
      &--open
        display: block

  .no-linear-input-range .noUi-target
    left: -5px

  @media (min-width: $desktop-md)
    .filter-toggle
      min-width: 154px

#{$component}-filter__meters
  svg
    min-width: 18px

#{$component}-filter__price
  svg
    min-width: 22px

#{$component}-filter__sticky-header
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
  padding-left: 15px
  padding-right: 15px
  line-height: 50px
  width: 100%
  font-size: 28px
  @media (min-width: $tablet)
    font-size: 32px

#{$component}-filter__type,
#{$component}-filter__area,
.dropdown-selector__action-icon
  .dropdown-selector__item + .dropdown-selector__item
    border-top: none
  .dropdown-selector__trigger
    line-height: 24px
  @media (min-width: $desktop-md)
    .dropdown-selector
      min-width: 184px

#{$component}-filter__type
  margin-top: 56px
  @media (min-width: $desktop-md)
    margin-top: 0
  .dropdown-selector
    position: relative
  .dropdown-selector__body
    z-index: 999
    @media (min-width: $desktop-md)
      min-width: 232px
      left: 0
  .dropdown-selector__item
    color: $black
    min-height: 40px
    padding-top: 8px
    padding-bottom: 8px
  .dropdown-selector__empty
    color: $black


#{$component}-filter__type,
#{$component}-filter__energy-rating
  @media (min-width: $desktop-md)
    border-bottom: 1px solid $bg-badge-rented-out
    .dropdown-selector__body
      position: relative
      box-shadow: none
    .dropdown-selector
      height: auto
    .dropdown-selector__trigger
      cursor: default
      height: auto
      padding: 20px 24px 0 24px
      &:hover,
      &--active
        border: none
    .dropdown-selector
      &__action-icon
        display: none
      &__placeholder-text
        @include fontSLH(20px, 24px)
        font-weight: 900
      &__column
        @include displayFlex(center)
        flex-wrap: wrap
        padding: 24px 24px 8px
      &__item
        padding: 0
        margin: 0 24px 16px 0
        min-height: unset
        &:hover
          background: $white
      &__item-icon
        margin-right: 8px

#{$component}-filter__area

  .dropdown__search-wrapper
    position: relative
    margin: 16px 16px 8px
    svg
      position: absolute
      left: 16px
      top: 12px

  .dropdown__search-input
    padding: 10px 16px 10px 42px
    line-height: 24px
    border-radius: 4px
    border: 1px solid #E0E2E2
    background: #FFF
    display: flex
    width: 100%
    height: 44px
    font-size: 16px
    outline: none !important

  .dropdown-selector__item-label
    display: flex
    width: 100%
    margin-left: 8px

  .dropdown-selector__chosen-tooltip
    @include fontSLH(16px, 24px)
    font-weight: 300 !important
    white-space: nowrap
    margin-left: 5px
    display: inline-flex
    @media (min-width: $desktop-md)
      font-size: 14px

  .dropdown-selector__select-buttons
    display: flex
    margin: 8px 0 8px
    padding: 0 16px
    @media (min-width: $tablet)
      padding: 0 32px
    @media (min-width: $from-tablet)
      padding: 0 8px
    @media (min-width: $desktop-md)
      padding: 0 16px
    p
      @include fontSLH(16px, 24px)
      font-family: MuseoSans, sans-serif
      cursor: pointer
      margin-bottom: 0
      text-decoration: underline
      &:first-child
        margin-right: 24px
        color: $green
        &:hover
          color: $green-hover
      &:last-child
        color: #5A6365
        &:hover
          color: $default

  .dropdown-selector
    position: relative
    width: 170px

  .dropdown-selector__body
    background: $white
    right: 0
    top: -1px
    @media (min-width: $desktop-md)
      width: 360px
      top: 44px
      max-height: calc(100vh - 210px)
      overflow-y: auto
      .dropdown-selector__separator
        display: block
      @media (max-height: 600px)
        max-height: 500px

  .dropdown-selector__button
    margin: 10px
    padding: 12px 24px 11px
    font-weight: 900
    text-align: center
    height: initial
    text-transform: uppercase

  .dropdown-selector__item-submenu
    max-height: 0
    overflow: hidden
    transition: max-height .2s ease-in-out
    &.opened
      margin-bottom: 8px
      background: #F6F6F6
    .dropdown-selector__item
      padding: 10px 24px
      @media (min-width: $tablet)
        padding: 10px 40px
      @media (min-width: $from-tablet)
        padding: 10px 16px
    .dropdown-selector__separator
      margin-top: 16px
      @media (min-width: $from-tablet)
        margin: 8px 0 0

  .dropdown-selector__item
    background-color: $border-light-grey
    align-items: flex-start
    min-height: auto
    padding: 10px 16px
    @media (min-width: $from-tablet)
      margin: 0
      .arrow-down
        right: 8px
    @media (min-width: $desktop-md)
      min-height: 44px
      //background-color: $white
      padding: 10px 16px
      &:hover
        background: $grey-bg
      .arrow-down
        right: 0
    .arrow-down
      position: relative
      top: 2px
      height: 24px
      margin-left: 8px
      i
        font-size: 20px
        pointer-events: none
      &.disabled
        display: none
    &.main
      background-color: $white
      &.sticky
        position: fixed
        top: 55px
        left: 0
        z-index: 9
        width: 100%
        @media (min-width: $desktop-md)
          position: sticky
          top: 0
          left: 0
      //&:hover
        //color: $green-hover
        //text-decoration: underline

  .dropdown-selector__column
    padding: 8px 0
    @media (min-width: $from-tablet)
      margin: 0 $mediumContainerIndentation
      width: auto
      min-width: auto
      background-color: $border-light-grey
    @media (min-width: $desktop-md)
      margin: 0
      background-color: $white

  .dropdown-selector__item--selected
    .dropdown-selector__item-label span
      &:not(.with-query)
        font-weight: 900
      span
        font-weight: 300

  .dropdown-selector__item-icon-checked
    @include displayFlex(center, center)
    border: 1px solid $green
    border-radius: 2px
    background-color: $green
    top: 2px
    position: relative
    margin-right: 8px
    i
      color: $white
      font-size: 18px

  .dropdown-selector__item-checkbox
    top: 1px
    position: relative
    margin-right: 8px

  .dropdown-selector__item-name
    display: flex
    align-items: flex-start

  .dropdown-selector
    width: auto
    min-width: 170px

  &.desktop
    display: none
    @media (min-width: $desktop-md)
      display: flex
    .dropdown-selector__trigger
      background: #F6F7F7

  &.mobile
    @media (min-width: $desktop-md)
      display: none

.sticky-inner-wrapper
  z-index: 200 !important
