// MOBILE_FIRST
@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.trusted-logos
  border-top: 3px solid $border-light-grey
  border-bottom: 3px solid $border-light-grey
  &__wrapper
    overflow: hidden
    display: flex
    align-items: center
    position: relative
    flex-direction: column
    @media (min-width: $tablet)
      flex-direction: row

  &__header
    @include font(14px, $default, false, bold)
    height: 100%
    display: flex
    align-items: center
    white-space: nowrap

    @media (max-width: $screen-s)
      width: 100%
      text-align: left
      margin-bottom: 10px
      position: relative
      margin-top: 20px

  &__list
    list-style: none
    padding: 0
    position: relative
    width: 100%
    flex-wrap: wrap
    display: flex
    justify-content: space-between
    margin: 0
    @media (min-width: $tablet)
      justify-content: center

  &__logo-item
    text-align: center
    margin: 0 0 20px 0
    @media (min-width: $mobile)
      margin-right: 30px
      margin-top: 20px
    @media (min-width: $desktop-md)
      margin: 20px 40px 20px 0

  // For IE
  &__logo-link
    display: inline-block
    min-width: auto

  img[data-sizes="auto"]
    height: 30px
    min-width: auto
    display: block
    @media (min-width: $tablet)
      height: 25px
    @media (min-width: $desktop-md)
      height: 35px
    @media (min-width: $desktop)
      height: 40px


  &--column
    .trusted-logos__wrapper
      flex-direction: column
    .trusted-logos__header
      width: 100%
      text-align: left
      margin-bottom: 10px
      position: relative
      margin-top: 20px
    .trusted-logos__list
      justify-content: space-between
    .trusted-logos__logo-item
      margin-top: 0
      margin-right: 0
