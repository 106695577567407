// MOBILE_FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

.FAQSection
  font-family: 'MuseoSans', sans-serif
  margin-bottom: 30px
  padding-top: 64px
  @media (min-width: $tablet)
    margin-bottom: 64px
  &.no-padding
    padding-top: 0

  &__title
    margin-bottom: 24px
    @media (min-width: $desktop-md)
      margin-bottom: 32px

  &__question-wrapper
    overflow: hidden
    border-top: 1px solid $bg-badge-rented-out

    &:last-child
      border-bottom: 1px solid $bg-badge-rented-out

    &:not(.FAQSection__question-wrapper--open)
      &:hover
        @media (min-width: $desktop-md)
          border-color: $border-light-grey
          & + div
            border-top-color: $border-light-grey
      .FAQSection__question:hover
        background-color: transparent
        @media (min-width: $desktop-md)
          background-color: $border-light-grey

    .FAQSection__question
      @include font(16px, $default, 24px)
      font-weight: 900
      padding: 15px 30px 15px 0
      position: relative
      user-select: none
      cursor: pointer
      margin: 0
      &:before
        @include beforeWithContent(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='.87'/%3E%3Cpath d='M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z'/%3E%3C/svg%3E"), $right: 0)
        @media (min-width: $tablet)
          top: 15px
        @media (min-width: $desktop-md)
          top: 23.5px
          right: 20px
        @media (min-width: $desktop)
          top: 22px
      @media (min-width: $tablet)
        padding: 15px 30px 15px 0
      @media (min-width: $desktop-md)
        padding: 23.5px 50px 23.5px 24px
      @media (min-width: $desktop)
        padding: 24px 50px 24px 24px

    &--close
      .FAQSection__answer
        min-height: 0
        height: 0
        padding: 0 0 0
        visibility: hidden
        animation: .25s ease 0s close
        @media (min-width: $tablet)
          animation: .25s ease 0s close768
        @media (min-width: $desktop-md)
          padding: 0 24px 0
          animation: .25s ease 0s close992
        @media (min-width: $desktop)
          padding: 0 24px
          animation: .25s ease 0s close1200
      .FAQSection__question:before
        transform: rotate(0deg)
        transition: transform ease 0.3s

    &--open
      .FAQSection__question:before
        transform: rotate(-180deg)
        transition: transform ease 0.3s

      .FAQSection__answer
        height: auto
        min-height: 61px
        padding: 0 0 29px
        visibility: visible
        animation: .25s ease 0s open
        @media (min-width: $tablet)
          animation: .25s ease 0s open768
          padding: 0 0 34px
        @media (min-width: $desktop-md)
          animation: .25s ease 0s open992
          padding: 8.5px 24px 45px
        @media (min-width: $desktop)
          animation: .25s ease 0s open1200
          padding: 8px 24px 53px

  &__answer
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, sans-serif
    min-height: 0
    height: 0
    padding: 0 0 0
    visibility: hidden
    @media (min-width: $desktop-md)
      padding: 0 24px 0
    @media (min-width: $desktop)
      padding: 0 24px

@keyframes open
  from
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 0 0
  to
    height: auto
    min-height: 61px
    visibility: visible
    padding: 0 0 29px

@keyframes open768
  from
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 0 0
  to
    height: auto
    min-height: 61px
    visibility: visible
    padding: 0 0 34px

@keyframes open992
  from
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 24px 0
  to
    height: auto
    min-height: 61px
    visibility: visible
    padding: 8.5px 24px 45px

@keyframes open1200
  from
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 24px
  to
    height: auto
    min-height: 61px
    visibility: visible
    padding: 8px 24px 53px

@keyframes close
  from
    height: auto
    min-height: 61px
    visibility: visible
    padding: 0 0 29px
  to
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 0 0

@keyframes close768
  from
    height: auto
    min-height: 61px
    visibility: visible
    padding: 0 0 34px
  to
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 0 0

@keyframes close992
  from
    height: auto
    min-height: 61px
    visibility: visible
    padding: 8.5px 24px 45px
  to
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 24px 0

@keyframes close1200
  from
    height: auto
    min-height: 61px
    visibility: visible
    padding: 8px 24px 53px
  to
    min-height: 0
    height: 0
    visibility: hidden
    padding: 0 24px
