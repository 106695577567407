// MOBILE-FIRST
@import '../../../src/stylesheets/variables'
@import '../../../src/stylesheets/mixins'

$mediumContainerIndentation: calc((100vw - 736px) / 2)

.dropdown-selector
  background-color: white
  min-height: 55px
  @media (min-width: $desktop-md)
    height: 44px
    min-height: 44px

  + .dropdown-selector
    margin-top: 20px

  &__empty
    padding: 10px
    color: $inactive

  &__placeholder-text
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, sans-serif
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    @media (min-width: $desktop-md)
      display: none
    &.mobile-hidden
      display: none
      @media (min-width: $desktop-md)
        @include truncate(100%)
        display: flex

  &__placeholder
    @include fontSLH(16px, 24px)
    font-family: MuseoSans, sans-serif
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    &.active
      @media (max-width: $screen-lg-md-max)
        font-family: MuseoSans, sans-serif
        font-weight: 900
        .dropdown-selector__placeholder-text
          font-family: MuseoSans, sans-serif
          font-weight: 900
        .dropdown-selector__selected-tooltip
          font-weight: 300
    @media (min-width: $desktop-md)
      @include truncate(100%)
      margin: 10px 0 10px 10px

  &__selected-tooltip
    @include fontSLH(10px, 14px)
    font-family: MuseoSans, sans-serif
    text-transform: capitalize
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    max-width: 117px
    @media (min-width: $tablet)
      max-width: 290px
    @media (min-width: $desktop-md)
      display: none

  &__trigger
    @include font(14px, $default, 18px)
    @include displayFlex(stretch, space-between)
    border-radius: 0
    background-color: $white
    border-bottom: 1px solid $bg-badge-rented-out
    transition: border-bottom ease-in 0.5s
    &:hover
      cursor: pointer
    @media (min-width: $desktop-md)
      padding: 0 8px 0 16px
      border: 1px solid $bg-badge-rented-out
      border-radius: 8px
      transition: border-bottom 0s
      &:hover, &--active
        border: 1px solid $green

    @media (max-width: $screen-lg-md-max)
      &--active
        border-bottom: 1px solid transparent !important

  &__action-icon
    @include displayFlex(center, center)
    color: $default
    cursor: pointer
    font-size: 16px
    margin-left: 8px

  &__header
    @include displayFlex(center, space-between)
    background-color: white
    border: 1px solid $link-water
    padding: 0 10px
    height: 41px

    + .dropdown-selector__header
      border-top: none
      border-bottom: 1px solid $link-water

  &__header--colored
    background-color: $green
    color: $white

  &__header-back
    svg
      margin-right: 10px
    &:hover
      cursor: pointer

  &__body
    transition: max-height ease 0.3s
    max-height: 0
    display: flex
    flex-direction: column
    overflow: hidden
    &--open
      max-height: 10000px
    @media (min-width: $desktop-md)
      position: absolute
      display: none
      border-radius: 8px
      box-shadow: 0 6px 15px 0 rgba(0,4,10,0.15)
      right: 0

      &--open
        display: block

  &__column
    flex-grow: 1
    width: 100%
    padding-bottom: 16px
    min-width: 100vw
    @media (min-width: $desktop-md)
      width: auto
      min-width: auto
    @media (min-width: $desktop-md)
      padding: 8px 0
      background: $white
    + .dropdown-selector__column
      border-left: 0
      @media (min-width: $tablet)
        border-left: 1px solid $bg-badge-rented-out
    .dropdown-selector__item + .dropdown-selector__item
      border-top: 1px solid rgba(206, 212, 218, 0.1)

  &__item
    @include displayFlex(center, flex-start)
    min-height: 35px
    background-color: $white
    padding: 8px 16px
    cursor: pointer
    @media (min-width: $tablet)
      padding: 8px 32px
    @media (min-width: $from-tablet)
      padding: 8px 0 8px 8px
      margin: 0 $mediumContainerIndentation
    @media (min-width: $desktop-md)
      padding: 0 16px
      margin: 0
    &:hover
      background: $grey-bg

  &__separator
    border-top: 1px solid $bg-badge-rented-out
    margin: 0 16px
    @media (min-width: $tablet)
      margin: 0 24px
    @media (min-width: $from-tablet)
      margin: 0 $mediumContainerIndentation
    @media (min-width: $desktop-md)
      display: none

  &__item-icon
    margin-right: 10px

  &__item--selected
    .dropdown-selector__item-icon
      @include square(20px)
      border: 1px solid $green
      border-radius: 2px
      background-color: $green

  &__item-checkbox
    @include square(20px)
    border: 1px solid $border-form-input
    border-radius: 2px
    background-color: $white
    svg
      visibility: hidden

  &__item-icon-checked
    display: flex
    svg
      visibility: visible
      fill: $white

  &__button
    background-color: $default
    border-radius: 6px
    padding: 7px 24px
    color: $white
    height: 30px
    line-height: initial
    &:hover
      cursor: pointer

  &__item-name
    @include fontSLH(16px, 24px)
    flex-grow: 1

  &__trigger-right
    @include displayFlex(center)


// LOADER
.lds-ellipsis
  @include boxSize(36px, 7px)
  display: inline-block
  position: relative
  margin: 17px auto

  div
    @include positionAbsolute(0)
    @include square(6px)
    border-radius: 50%
    background: $inactive
    animation-timing-function: cubic-bezier(0, 1, 1, 0)

  div:nth-child(1)
    left: 6px
    animation: lds-ellipsis1 0.6s infinite

  div:nth-child(2)
    left: 6px
    animation: lds-ellipsis2 0.6s infinite

  div:nth-child(3)
    left: 16px
    animation: lds-ellipsis2 0.6s infinite

  div:nth-child(4)
    left: 26px
    animation: lds-ellipsis3 0.6s infinite

@keyframes lds-ellipsis1
  0%
    transform: scale(0)

  100%
    transform: scale(1)

@keyframes lds-ellipsis3
  0%
    transform: scale(1)

  100%
    transform: scale(0)

@keyframes lds-ellipsis2
  0%
    transform: translate(0, 0)

  100%
    transform: translate(10px, 0)
