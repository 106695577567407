@import '../../src/stylesheets/variables'
@import '../../src/stylesheets/mixins'

.breadcrumbs
  background-color: $white
  padding: 0
  @media (max-width: $mobile)
    height: 0
    overflow: hidden

  .container
    padding: 16px
    line-height: 20px

  &.details-page
    background-color: $white

  &__link-wrapper
    @include font(12px, $light-grey-text, 12px)
    border: 0
    display: inline-flex
    align-items: center
    svg
      fill: $light-grey-text
      margin: 0 5px

  &__link
    color: $black
    text-decoration: none
    font-weight: 300
    &:hover
      text-decoration: underline
    &--disabled
      color: $gull-grey-text
      &:hover
        text-decoration: none

.breadcrumbs-list-view
  @media (max-width: $mobile)
    height: initial
